@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *

.bs-w-reader
  article
    &:first-child
      .bs-w-article-content
        padding-block-start: rem-fn(30)

        @include media-query.from(media.$md-min)
          padding-block-start: 0

    &:nth-child(even), &.bs-w-r-free-app
      background-color: $bs-light-grey-background
      position: relative

    h2
      margin-block-end: rem-fn(60)

      @include media-query.from(media.$md-min)
        max-inline-size: 100%

.bs-w-r-intro
  .bs-w-button
    margin-block-end: rem-fn(30)

    @include media-query.from(media.$md-min)
      inline-size: rem-fn(280)
      margin-inline: auto

  .bs-w-or-separator
    margin-block-end: rem-fn(30)
    margin-inline: rem-fn(25)

    @include media-query.from(media.$md-min)
      max-inline-size: rem-fn(538)

  @include media-query.from(media.$md-min)
    margin-block-start: rem-fn(60)

    .bs-w-article-content
      .bs-w-r-i-middle
        text-align: center

        .bs-w-r-sub
          margin-block-end: rem-fn(50)

        .bs-w-or-separator
          margin-inline: auto
          max-inline-size: rem-fn(264)

        .bs-w-r-download
          margin-inline: auto
          max-inline-size: rem-fn(280)

  @include media-query.from(media.$lg-max)
    .bs-w-article-content
      display: grid
      grid-template-columns: rem-fn(250) rem-fn(71) rem-fn(19) rem-fn(170) rem-fn(127) rem-fn(54) 1fr rem-fn(32) rem-fn(202) rem-fn(146) rem-fn(130) rem-fn(160)
      grid-template-rows: rem-fn(25) rem-fn(86) rem-fn(35) rem-fn(72) rem-fn(63) rem-fn(67)
      max-inline-size: rem-fn(media.$wide-content-max-width)

      .bs-w-s-b-top-reviewer
        grid-column: 1/2
        grid-row: 2/7

      .bs-w-s-b-total-books
        grid-column: 4/5
        grid-row: 3/6

      .bs-w-s-b-book-rating
        grid-column: 3/7
        grid-row: 9/13

      .bs-w-r-i-middle
        grid-column: 6/9
        grid-row: 1/11

      .bs-w-s-b-review
        grid-column: 8/11
        grid-row: 7/13

      .bs-w-s-b-author
        grid-column: 10/12
        grid-row: 1/4

      .bs-w-s-b-total-reviews
        grid-column: 12/13
        grid-row: 5/8

.bs-w-r-sub
  margin-block-end: rem-fn(30)

article.bs-w-r-review-copies
  h2
    margin-block-end: rem-fn(20)
    max-inline-size: rem-fn(259)

  p
    margin-block-end: rem-fn(30)

    + p
      margin-block-end: rem-fn(40)

  .bs-w-button
    margin-block-end: rem-fn(60)

    @include media-query.from(media.$md-min)
      margin-block-end: 0

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      column-gap: rem-fn(40)
      grid-auto-rows: max-content
      grid-template-columns: 1fr rem-fn(552)

      h2
        max-inline-size: 100%

      h2, p, .bs-w-button
        grid-column: 1

      .bs-w-review-copy
        align-self: center
        grid-column: 2
        grid-row: 1/4

.bs-w-r-download
  display: flex
  flex-flow: column
  text-align: center

  strong
    color: get-color(bs-b)
    margin-block-end: rem-fn(15)

.bs-w-button-group
  display: flex

  a
    align-items: center
    background-color: #F8F8F8
    block-size: rem-fn(64)
    border: rem-fn(1) solid #D0D0D0
    color: get-color(bs-gm)
    display: flex
    flex: 0 0 33.333%
    justify-content: center

    &:first-child
      border-top-left-radius: $border-radius-base
      border-bottom-left-radius: $border-radius-base

    &:last-child
      border-top-right-radius: $border-radius-base
      border-bottom-right-radius: $border-radius-base

    & + a
      border-inline-start-width: 0

article.bs-w-r-support
  .bs-w-card
    &:first-of-type
      svg
        color: get-color(bs-nb)

    p, svg
      margin-block-end: rem-fn(20)

    p
      color: get-color(bs-gl)

    svg
      color: get-color(bs-g)

    h3
      margin-block-end: rem-fn(40)
      max-inline-size: rem-fn(269)

  .bs-w-member-ellipse
    block-size: rem-fn(390)
    margin-block-end: rem-fn(80)
    margin-inline: auto

    .bs-w-m-e-one
      block-size: rem-fn(390)
      inline-size: rem-fn(390)

    .bs-w-m-e-two
      block-size: rem-fn(290)
      inline-size: rem-fn(290)
      left: calc(50% - #{rem-fn(145)})
      top: calc(50% - #{rem-fn(145)})

    .bs-w-m-e-three
      block-size: rem-fn(198)
      inline-size: rem-fn(198)
      left: calc(50% - #{rem-fn(99)})
      top: calc(50% - #{rem-fn(99)})

    .bs-w-m-e-avatar-1
      left: rem-fn(4)
      top: rem-fn(35)

    .bs-w-m-e-avatar-2
      left: auto
      right: rem-fn(5)
      top: rem-fn(35)

    .bs-w-m-e-avatar-3
      bottom: rem-fn(34)
      left: rem-fn(4)
      top: auto

    .bs-w-m-e-avatar-4
      bottom: rem-fn(34)
      right: rem-fn(5)
      top: auto

    .bs-w-m-e-centre
      background-color: get-color(bs-w)
      block-size: rem-fn(116)
      border: rem-fn(1) solid #E0E4E8
      border-radius: 50%
      box-shadow: $box-shadow-base
      inline-size: rem-fn(116)
      left: calc(50% - #{rem-fn(58)})
      position: absolute
      top: calc(50% - #{rem-fn(58)})

      img
        left: calc(50% - #{rem-fn(31)})
        top: calc(50% - #{rem-fn(31)})

    .bs-w-popup
      display: flex
      flex-flow: column
      inline-size: rem-fn(182)
      left: 50%
      padding: rem-fn(20)
      top: rem-fn(180)
      transform: translateX(-50%)

  @include media-query.from(media.$md-min)
    .bs-w-article-content
      display: grid
      column-gap: rem-fn(100)
      grid-template-areas: 'heading heading' 'step1 step2' 'reviewers review'
      grid-template-columns: rem-fn(532) 1fr

      h2
        grid-area: heading
        max-inline-size: rem-fn(760)

      .bs-w-card
        text-align: left
        padding-inline: 0

        &:nth-child(2)
          grid-area: step1

        &:nth-child(4)
          grid-area: step2

        p
          margin-block-end: rem-fn(5)

        h3
          max-inline-size: 100%

    .bs-w-member-ellipse
      grid-area: reviewers
      margin-block-end: 0

    .bs-w-book-review
      grid-area: review

.bs-w-reader article.bs-w-r-free-app
  background-color: get-color(bs-nb)
  color: get-color(bs-w)
  overflow: hidden

  &:before
    background-color: inherit

  h2
    color: get-color(bs-w)

  .bs-w-button-group
    margin-block-end: rem-fn(40)

    a
      background-color: transparent
      block-size: rem-fn(78)
      border: 0
      color: get-color(bs-w)
      flex-flow: column

      svg
        block-size: rem-fn(27)
        inline-size: rem-fn(48)

      span
        margin-block-start: auto

      &:first-child
        align-items: flex-start

        svg
          block-size: rem-fn(35)
          inline-size: rem-fn(29)

        span
          margin-inline-start: rem-fn(3)


      &:last-child
        align-items: flex-end

        svg
          block-size: rem-fn(35)
          inline-size: rem-fn(33)
          margin-inline-end: rem-fn(4)


  .bs-w-or-separator
    align-items: center
    margin-block-end: rem-fn(40)
    margin-inline: 0

    strong
      background-color: get-color(bs-nb)
      position: relative
      z-index: 10

    &:before
      border-block-start-color: rgba(255, 255, 255, 0.1)
      z-index: 0

    @include media-query.from(media.$md-min)
      max-inline-size: rem-fn(264)

  .bs-w-button
    inline-size: rem-fn(280)
    margin-block-end: rem-fn(20)
    margin-inline: auto

    @include media-query.from(media.$md-min)
      margin-inline: 0

  p
    margin-block-end: rem-fn(80)
    text-align: center

  .bs-w-article-content
    padding-block-end: 0

    @include media-query.from(media.$md-min)
      column-gap: rem-fn(115)
      display: grid
      grid-template-areas: 'title devices' 'downloads devices' 'separator devices' 'button devices' 'text devices'
      grid-template-columns: max-content 1fr

      h2
        grid-area: title

      .bs-w-button-group
        grid-area: downloads
        max-inline-size: rem-fn(310)

      .bs-w-r-or-separator
        grid-area: separator
        max-inline-size: rem-fn(264)

      .bs-w-button
        grid-area: button

      p
        grid-area: text
        text-align: left

      .bs-w-r-devices
        block-size: rem-fn(550)
        grid-area: devices
        max-inline-size: 100%

.bs-w-r-devices
  block-size: rem-fn(200)
  inline-size: 100%
  margin-inline: auto
  max-inline-size: rem-fn(316)
  position: relative

  img
    bottom: 0
    filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.35))
    position: absolute
    z-index: 0

    &:first-child
      bottom: rem-fn(-35)
      left: rem-fn(159)

      @include media-query.from(media.$md-min)
        bottom: rem-fn(-124)
        left: rem-fn(419)

    &:nth-child(2)
      bottom: rem-fn(-45)
      left: rem-fn(92)
      z-index: 10

      @include media-query.from(media.$md-min)
        bottom: rem-fn(-152)
        left: rem-fn(223)

    &:last-child
      bottom: rem-fn(-68)
      left: rem-fn(17)
      z-index: 0

      @include media-query.from(media.$md-min)
        bottom: rem-fn(-220)
        left: 0


