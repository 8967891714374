@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use '../css/functions/get-color' as *
@use '../css/variables/border-radius' as *
@use '../css/variables/box-shadow' as *

.bs-w-book-review
  background-color: get-color(bs-w)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  display: flex
  flex-flow: column
  margin: 0
  padding: rem-fn(40)

  > strong
    font-size: rem-fn(20)
    line-height: line-height(27, 20)
    margin-block-end: rem-fn(10)

  .bs-w-rating
    margin-block-end: rem-fn(30)

  p
    font-size: rem-fn(18)
    line-height: line-height(32, 18)
    margin-block-end: rem-fn(55)
