@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as rf
@use '~booksprout-app/src/css/functions/line-height-fn' as lhf
@use 'src/css/functions/get-color' as fn
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/media' as media
@use 'src/css/variables/border-radius' as *

.bs-w-review-copy
  figure
    border: rf.rem-fn(1) solid fn.get-color(bs-o)
    border-radius: $border-radius-base
    display: flex
    flex-flow: column
    row-gap: rf.rem-fn(20)
    margin: 0
    padding: rf.rem-fn(20)

    img
      block-size: rf.rem-fn(169)
      border-radius: $border-radius-base
      max-inline-size: rf.rem-fn(170)
      object-fit: contain

      @include media-query.from(media.$md-min)
        block-size: rf.rem-fn(270)
        max-inline-size: rf.rem-fn(220)

    figcaption
      align-items: center
      display: flex
      flex-flow: column
      row-gap: rf.rem-fn(20)

    @include media-query.from(media.$md-min)
      column-gap: rf.rem-fn(30)
      display: grid
      grid-template-columns: max-content 1fr
      padding: rf.rem-fn(30)

      img
        grid-column: 1
        grid-row: 1/4

      .bs-w-r-c-book
        grid-column: 2
        grid-row: 1

      figcaption
        grid-column: 2
        grid-row: 2

      .bs-w-r-c-b-details
        margin-block-start: rf.rem-fn(30)

      figcaption
        align-items: flex-start

  &:hover,
  &:focus
    text-decoration: none

    h2
      text-decoration: underline

.bs-w-r-c-book
  column-gap: rf.rem-fn(20)
  display: flex

.bs-w-chip
  background-color: fn.get-color(bs-glght)
  color: fn.get-color(bs-b)
  font-size: rf.rem-fn(14)
  line-height: lhf.line-height(19, 14)
  padding-block: rf.rem-fn(8)
  padding-inline: rf.rem-fn(10)

.bs-w-r-c-available
  align-items: center
  column-gap: rf.rem-fn(15)
  display: flex

  svg
    color: fn.get-color(bs-nb)
