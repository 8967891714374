@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *

.bs-w-stats-box
  align-items: center
  background-color: get-color(bs-w)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  display: flex
  flex-flow: column
  padding: rem-fn(40)
  text-align: center

  strong:not(.bs-w-s-b-name):not(.bs-w-s-b-book-title)
    color: get-color(bs-gl)

  .bs-w-member figcaption
    text-align: left

    strong
      color: get-color(bs-b) !important

.bs-w-s-b-top-reviewer
  padding-block: rem-fn(70)

  img
    margin-block-start: rem-fn(40)
    margin-block-end: rem-fn(20)

.bs-w-s-b-book-rating
  .bs-w-rating
    flex-flow: column

    .q-rating
      margin-block-end: rem-fn(20)

    span
      font-size: rem-fn(18)
      line-height: line-height(25, 18)
      margin-block-end: rem-fn(5)

.bs-w-s-b-review
  text-align: left

  .bs-w-s-b-book-title
    align-self: flex-start
    margin-block-end: rem-fn(10)

  .bs-w-rating
    align-self: flex-start
    margin-block-end: rem-fn(20)

  p
    margin-block-end: rem-fn(30)

  .bs-w-member
    align-self: flex-start

.bs-w-s-b-count
  color: get-color(bs-b)
  font-size: rem-fn(26)
  line-height: line-height(35, 26)
  margin-block-end: rem-fn(10)

.bs-w-s-b-name
  color: get-color(bs-b)
  margin-block-end: rem-fn(2)

.bs-w-s-b-book-title
  color: get-color(bs-b)
  font-size: rem-fn(20)
  line-height: line-height(27)
  margin-block-end: rem-fn(5)

.bs-w-s-b-pen-name
  margin-block-end: rem-fn(30)
